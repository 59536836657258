@import 'react-toastify/dist/ReactToastify.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ant-btn-primary a {
  color: white;
}

.site-layout .site-layout-background {
  background: white;
  border-left: 1px solid #f0f0f0;
}

[data-theme='dark'] .site-layout .site-layout-background {
  background: #141414;
  border-left: 1px solid #303030;
}

#root {
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.financial-disclosure-btn {
  white-space: normal;
  text-align: left;
  display: grid;
  height: 100% !important;
  grid-auto-flow: column;
  grid-gap: 0.5rem;
  height: auto;
}

.financial-disclosure-btn.unselected {
  opacity: 0.7;
}

.financial-disclosure-btn [role='img'] {
  margin-top: 0.25rem;
}

.financial-disclosure-btn svg {
  width: 1.5rem;
  height: 1.5rem;
}

.ant-btn-danger {
  background-color: #d92d20;
  border-color: #d92d20;
  color: white;
}

.ant-btn-danger:not(:disabled):hover,
.ant-btn-danger:not(:disabled):active {
  background-color: #ff7875;
  border-color: #ff7875;
  color: white;
}

.flex-row .ant-form-item-control-input-content {
  display: flex;
  margin-right: 0.25rem;
}

.delete-flag {
  color: #b13234;
  font-size: 0.75rem;
  margin-top: 0.25rem;
}

.edit-credit-input input {
  font-size: 14px;
  padding: 0;
  border: 0;
  border-bottom: 1px solid #982dac;
  border-radius: 0;
  display: inline-block;
  background-color: transparent;
  margin-top: 3px;
}

.edit-credit-input button {
  height: 25px;
}

.edit-credit-input .ant-form-item-control {
  max-width: 100%;
}

.edit-credit-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.benchmarks-expanded {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.single-benchmark {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem;
}

.single-benchmark-level {
  font-weight: bold;
  margin: 1rem 0 0;
}

.single-benchmark .ant-image {
  width: 60px;
  height: 60px;
}

.center-item {
  margin: 0 auto 1rem;
  width: 100%;
  max-width: 800px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.warning-block {
  border: 1px solid #ffbe8f;
  background: #fffaf7;
  color: #c34c19;
  border-radius: 0.5rem;
  width: 100%;
  padding: 0.5rem 1rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.info-block {
  border: 1px solid #8fdaff;
  background: #f7feff;
  color: #1971c3;
  border-radius: 0.5rem;
  width: 100%;
  padding: 0.5rem 1rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  text-align: left;
}

.text-btn {
  appearance: none;
  border: 0;
  background: unset;
  color: #c34c19;
  font-weight: normal;
  cursor: pointer;
  padding: 0;
  margin: 0 0 0 0.25rem;
}

.warning-icon {
  margin-right: 0.25rem;
  font-size: 1.25rem;
}

.info-icon {
  margin-right: 0.5rem;
  font-size: 1rem;
}

.more-details {
  border-top: 1px solid #ffbe8f;
  margin-top: 0.25rem;
  padding-top: 0.25rem;
  font-weight: normal;
  width: 100%;
  flex-grow: 1;
  font-size: 0.75rem;
}

.title-column {
  border-bottom: 1px solid #d9d9d9;
  color: rgb(117, 117, 117);
  line-height: 16px;
  margin: 0 auto;
  margin-bottom: 1.5rem;
  padding: 0 1rem;
  text-align: left;
}

.no-padding-panel {
  .ant-collapse-content-box {
    padding: 0 !important;
  }

  form {
    padding: 0.5rem 0.5rem 0 !important;
  }

  .ant-pagination {
    padding: 0 0.5rem !important;
  }

  table,
  th {
    border-radius: 0 !important;
    border-start-start-radius: 0 !important;
    border-start-end-radius: 0 !important;
  }

  td {
    border-width: 0 0 1px 0 !important;
  }
}

[data-theme='light'] .no-padding-panel thead.ant-table-thead > tr > th {
  background: #eee !important;
  border-radius: 0 !important;
}

[data-theme='dark'] .no-padding-panel {
  thead.ant-table-thead > tr > th {
    background: #333 !important;
    border-radius: 0 !important;
  }

  a {
    color: #96ccff;

    &:hover {
      color: #40a9ff;
    }
  }
}

.sticky-box .ant-tabs-tab {
  opacity: 0.5;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 1;
  }
}

.sticky-box .ant-tabs-tab-active {
  opacity: 1;
}

[data-theme='dark'] .sticky-box .ant-tabs-tab-active .ant-tabs-tab-btn,
[data-theme='dark'] .sticky-box .ant-tabs-tab:hover {
  color: #c181cd;
}

[data-theme='dark'] .ant-tabs .ant-tabs-ink-bar {
  background: #c181cd;
}
